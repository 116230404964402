import React from 'react';

const ResultList = (props) => {
    return (
        <div className="result-list">
            <h1>{props.title}</h1>
        </div>
    )
}

const EventSelector = (props) => {
    return (
        <div className="event-selector">
            <form onSubmit={props.fetchData}>
                <input type="text" name="tid"></input>
                <input type="text" name="d"></input>
                <input type="submit" value="Hae"></input>
            </form>
        </div>
    )
}

class CompetitionResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            data: {},
            error: ""
        };
    }

    handleFormSubmission = (e) => {
        e.preventDefault();
        this.fetchEventData(e.target.tid.value, e.target.d.value);
    }

    fetchEventData = (tid, d) => {
        const url = "https://api.kehakarhut.fi/api/competition-results/" + tid + "/" + d;
        fetch(url,
            {
                mode: 'cors'
            })
            .then(
                (result) => {
                    console.log(result);
                    /*
                    this.setState({
                        isLoaded: true,
                        items: result.items
                    });
                    */
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log(error);
                    this.setState({
                        isLoaded: false,
                        error
                    });
                }
            )
    }

    render() {
        if (this.state.data) {
            return (
                <div>
                    <EventSelector fetchData={this.handleFormSubmission}></EventSelector>
                </div>
            )
        }
        else {
            return (
                <div>
                    <EventSelector fetchData={this.handleFormSubmission}></EventSelector>
                    <ResultList></ResultList>
                </div>
            )
        }
    }
}

export default CompetitionResults;